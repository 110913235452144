body {
  margin: 0;
  padding: 0;
  background-color: var(--color-black);
  color: var(--color-white);
  font-family: Kanit, Arial;
  font-weight: 400;
  font-size: 16px;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-color: var(--color-white);
}

p {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.light-mode-app {
  background-color: var(--color-white);
  color: var(--color-black);
  border-color: var(--color-black);
}

.light-mode-nav-bar {
  background-color: var(--color-white);
  color: var(--color-black);
  border-color: var(--color-black);
}

.light-mode-sections {
  background-color: var(--color-light-gray);
}

.light-mode-footer {
  border-color: var(--color-black);
}

.light-mode-header-borders {
  border-color: var(--color-green);
}

.light-mode-special-text,
.light-mode-repo-link,
.light-mode-zoom-text
{
  color: var(--color-green);
}

.all-btns {
  border: 2px solid transparent;
  border-radius: 5px;
}

.all-btns,
.footer-btns {
  transition: color 0.3s; 
  cursor: pointer;
}

.all-btns:hover,
.footer-btns:hover {
  color: var(--color-purple);
  border-color: var(--color-purple);
}

.text-highlights {
  color: var(--color-red);
}

.text-underline {
  text-decoration: underline;
}

/* Responsive Web Design Code */

@media (max-width: 640px) {
  .all-btns {
    border: 1px solid transparent;
  }
}