.projects-section {
  display: grid;
  grid-template-columns: 6fr 1fr;
  text-align: left;
}
  
.project {
  display: grid;
  grid-template-rows: 0.8fr 3.5fr 4fr 1fr;
  margin: 0.5vw;
}

.project-top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project-header {
  flex: 1;
  font-size: 1.8vw;
  margin-bottom: 0.5vw;
  font-weight: 500;
}

.project-repo-button {
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  padding: 0.5em;
  color: var(--color-yellow);
}

.project-repo-button-text {
  font-size: 1.5vw;
  margin-left: 1vw;
}

.project-text-section {
  flex: 6;
  text-align: justify;
  font-size: 1.1vw;
  margin-bottom: 0.5em;
}

.project-paragraphs {
  display: flex;
  margin-top: 0.5em;
}

.project-paragraphs-link {
  color: var(--color-yellow);
  cursor: pointer;
}

.project-gifs-section {
  flex: 6;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.project-gif {
  position: relative;
  height: 15vw;
  width: 22.5vw;
  transition: transform 0.5s ease;
  transform-origin: bottom left;
  z-index: 0;
}

.project-gif:hover {
  transform: scale(1.5);
  transform-origin: bottom left;
  z-index: 1;
}

.project-gif img {
  max-width: 100%;
  border: 5px solid;
  border-radius: 25px;
}

.project-gif-label {
  position: absolute;
  bottom: -0.5em;
  right: 1em;
  background-color: var(--color-purple);
  color: var(--color-white);
  padding: 0px 2px 0px 2px;
  font-size: 1vw;
  border-radius: 2px;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.project-bottom-section {
  display: flex;
  flex-direction: row;
  margin-top: 0.6em;
  font-size: 1vw;
}

.project-technologies {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}

.project-technologies-header {
  font-weight: 500;
  text-decoration: underline;
}

.project-technologies-text-container {
  display: flex;
  flex-direction: row;
}

.project-technologies-text {
  margin-right: 1.5em;
}

.project-zoom-info {
  flex: 1;
  display: flex;
  align-items: center;
  color: var(--color-yellow);
}

.project-selection-buttons-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* Responsive Web Design Code */

@media (max-width: 1400px) {
  .projects-section {
    grid-template-columns: 1fr;
    grid-template-rows: 6fr 1fr;
  }

  .project-selection-buttons-container {
    flex-direction: row;
  }

  .project-header {
    font-size: 2vw;
  }
  
  .project-repo-button-text {
    font-size: 1.5vw;
  }
  
  .project-text-section {
    font-size: 1.5vw;
  }
  
  .project-gif {
    height: 15vw;
    width: 22.5vw;
  }
  
  .project-gif-label {
    font-size: 1.2vw;
  }
  
  .project-bottom-section {
    font-size: 1.5vw;
  }
}

@media (max-width: 1000px) {
  .project-header {
    font-size: 2.5vw;
  }
  
  .project-repo-button-text {
    font-size: 2w;
  }
  
  .project-text-section {
    font-size: 2vw;
  }
  
  .project-gif {
    height: 18vw;
    width: 27vw;
  }
  
  .project-gif-label {
    font-size: 1.2vw;
  }
  
  .project-bottom-section {
    font-size: 1.7vw;
  }
}

@media (max-width: 800px) {
  .projects-section {
    grid-template-rows: 7fr 1fr;
  }

  .project {
    grid-template-rows: 0.5fr 2.5fr 5.5fr 0.8fr;
  }
  
  .project-gifs-section {
    flex-wrap: wrap;
  }

  .project-gif {
    height: 25vw;
    width: 37.5vw;
  }
  
  .project-gif:hover {
    transform: scale(1.7);
    transform-origin: bottom;
  }
}

@media (max-width: 500px) {
  .project {
    grid-template-rows: 0.3fr 2.5fr 5fr 0.4fr;
  }

  .project-header {
    font-size: 3vw;
  }
  
  .project-repo-button-text {
    font-size: 2.5w;
  }
  
  .project-text-section {
    font-size: 2.5vw;
  }

  .project-gifs-section {
    flex-direction: column;
  }

  .project-gif {
    height: 30vw;
    width: 45vw;
  }
  
  .project-gif-label {
    font-size: 1.5vw;
  }
}


/* Used for Fade In/Out function */

.fade-out {
  animation-name: fade-out-animation;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

.fade-in {
  animation-name: fade-in-animation;
  animation-duration: 0.5s;
  animation-timing-function: ease-in; 
}

@keyframes fade-out-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}