.welcome-section {
    display: flex;
    flex-direction: row;
  }
  
  .welcome-section-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .welcome-section-text-top {
    font-size: 5vw;
    font-weight: 700;
  }
  
  .welcome-section-text-bottom {
    font-size: 4vw;
  }
  
  .welcome-section-contact {
    display: flex;
    margin-top: 2em; 
  }
  
  .github-button,
  .linkedin-button,
  .mail-button {
    font-size: 2vw;
    padding: 10px 15px 10px 15px;
    margin: 1em;
  }
  
  .welcome-section-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: none;
  }
  
  .welcome-section-image img {
    max-width: 80%;
    height: auto;
  }
  
  /* Responsive Web Design Code */
  
  @media (max-width: 1000px) {
    .welcome-section {
      display: flex;
      flex-flow: column-reverse;
    }
  
    .welcome-section-text {
      justify-content: flex-start;
    }

    .welcome-section-text-top {
      font-size: 7vw;
    }
    
    .welcome-section-text-bottom {
      font-size: 6vw;
    }

    .github-button,
    .linkedin-button,
    .mail-button {
      font-size: 3vw;
    }
  
    .welcome-section-image img {
      max-width: 60%;
    }
  }

  @media (max-width: 640px) {
    .welcome-section-image img {
      max-width: 80%;
    }

    .github-button,
    .linkedin-button,
    .mail-button {
      font-size: 4vw;
    }
  }