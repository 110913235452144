  .welcome-section {
    min-height: 100vh;
  }
  
  .about-section,
  .resume-section,
  .projects-section {
    margin-left: 1vw;
    margin-right: 1vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    min-height: calc(100vh - 9vw);
    background-color: var(--color-dark-gray);
    border-radius: 10px;
  }
  
  .section-headers {
    display: inline-block;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
    font-size: 2.5vw;
    font-weight: 500;
    border-top: 0.25vw solid var(--color-yellow);
    border-bottom: 0.25vw solid var(--color-yellow);
  }