.resume-section {
  display: flex;
  flex-direction: row;
}

.resume-section-left {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  flex: 1;
}

#resume-pl {
  display: none;
}

.resume-cv-container {
  width: 100%; 
  height: 90%; 
  margin-bottom: 1em;
  flex: 1;
}

.resume-cv-link {
  color: var(--color-yellow);
}

.resume-section-right {
  margin-left: 1em;
  margin-top: 0.5em;
  font-size: 24px;
  text-align: left;
  flex: 1;
}

.resume-section-text {
  margin-bottom: 2vw;
}

.resume-section-lists {
  font-size: 16px;
}

/* Responsive Web Design Code */

@media (max-width: 1200px) {
  .resume-section-right {
    font-size: 20px;
  }
}

@media (max-width: 640px) {
  .resume-section {
    flex-direction: column;
    text-align: center;
  }

  .resume-cv-container {
    max-height: 50vh;
  }

  .resume-cv-link {
    font-size: 2.5vw;
  }

  .resume-section-right {
    font-size: 2.5vw;
  }

  .resume-section-lists {
    font-size: 2vw;
  }
}

@media (max-width: 450px) {
  .resume-cv-link {
    font-size: 3.5vw;
  }

  .resume-section-right {
    font-size: 3.5vw;
  }

  .resume-section-lists {
    font-size: 3vw;
  }
}