.about-section {
    display: grid;
    align-content: space-around;
    text-align: center;
}

.about-section-text {
    font-size: 2.7vw;
}

/* Responsive Web Design Code */

@media (max-width: 1200px) {
    .about-section-text {
        font-size: 4vw;
    }
}

@media (max-width: 640px) {
    .about-section-text {
        font-size: 5vw;
    }
}

@media (max-width: 450px) {
    .about-section-text {
        font-size: 6vw;
    }
}